// src/stores/counter.js
import { defineStore } from 'pinia';

export const useCounterStore = defineStore('counter', {
  // 定义 state，类似于组件中的 data
  state: () => ({
    downloadId:'1866382956340318208',
    publicVapidKey : 'BPrdKJ3iRQnAXK_DgCPkkK6C0HOezUsE9UDWijT2Vz9k2qKjLuaatjrDyOR6G_nuOl_44gSLq0pI7ZrdRx4kkv4',
    downloadUrl : '/prod-api',
    type:'facebook',
    pixelCode:'手动阀手动阀',
    model:'模板一',
    // appurl:'https://www.zhihu.com/',
    appurl:'https://www.zhihu.com/',
    urltype:'1',
  }),
});
